<template>
  <div class="add-comment-wrapper">
    <div class="add-comment-container" :class="{ active: isActive }">
      <!-- reference URL ( https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#rendering ) -->
      <ValidationObserver v-slot="{ invalid }" ref="form">
        <form @submit.prevent="submitForm">
          <ValidationProvider
            name="Comment"
            rules="required|max:10000"
            v-slot="{ errors }"
          >
            <div class="form-group d-flex">
              <div>
                <img
                  v-if="isActive === true"
                  :src="require('@/assets/images/icons/comment-blue.svg')"
                  alt="comment"
                  width="13"
                  height="13"
                />
                <img
                  v-else
                  :src="require('@/assets/images/icons/comment-grey.svg')"
                  alt="comment"
                  width="13"
                  height="13"
                />
              </div>
              <div class="comment-textarea-wrapper">
                <textarea
                  class="comment-textarea"
                  id="commentBody"
                  :placeholder="$t('discussions.comment.add')"
                  rows="1"
                  v-model="commentBody"
                  @focus="togglePostBtn = true"
                  @blur="togglePostBtn = false"
                ></textarea>
              </div>
              <div>
                <button
                  class="btn btn-link"
                  :disabled="invalid"
                  :class="{
                    active: isActive
                  }"
                  type="submit"
                >
                  {{ $t("discussions.form.navigation.post") }}
                </button>
              </div>
            </div>
            <span class="error mb-2">{{ errors[0] }}</span>
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    responseId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      commentBody: "",
      togglePostBtn: false
    };
  },
  computed: {
    isActive() {
      return this.togglePostBtn === true || this.commentBody.length > 0;
    }
  },
  methods: {
    submitForm() {
      let obj = {
        course_id: this.$route.params.id,
        response_id: this.responseId,
        body: this.commentBody
      };
      this.$store.dispatch("createResponseComment", obj).then(() => {
        this.$emit("commentAdded");
        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.$refs.form.reset();
          this.commentBody = "";
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
[dir="rtl"] {
  .add-comment-wrapper {
    .add-comment-container {
      .comment-textarea-wrapper {
        margin: 0 10px 0 0;
      }
    }
  }
}
.add-comment-wrapper {
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid $brand-neutral-200;
  .add-comment-container {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 5px 20px 5px 15px;
    border: 1px solid transparent;
    transition: border 0.3s;

    &:hover,
    &.active {
      border: 1px solid #0043ac;
    }

    .form-group {
      margin: 0;

      .btn-link {
        color: #0043ac;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.25px;
        padding: 0;
        opacity: 0;
        transition: opacity 0.3s;

        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          text-decoration: none;
        }

        &.active {
          opacity: 1;
        }
      }
    }

    .comment-textarea-wrapper {
      width: calc(100% - 68px);
      margin-left: 10px;
    }
    .comment-textarea {
      width: 100%;
      border: 0;
      background: transparent;
      padding: 0;
      color: #52575c;
      font-size: 14px;
      line-height: 24px;
      display: inline-block;
      vertical-align: bottom;
      resize: none;
      cursor: pointer;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
.error {
  color: red;
  font-size: 12px;
}
</style>
